 /**
 * ww-Blank Main stylehsheet
 *
 * @author Andreas Glimbrant, Johan Palmfjord, Joel Gustafsson
 * @url http://www.wasabiweb.se
 * @copyright Wasabi Web AB
 * @version 1.0
 */

/*	---------------------
	Container
	---------------------
*/
@media (min-width: 1200px) {
	.container,.navbar-static-top .container,.navbar-fixed-top .container,.navbar-fixed-bottom .container {
		width: 1200px; /* Set max width on container */
	}
}

/*	-----------------------------------------
	Reset by Andreas Glimbrant @ Wasabi Web
	-----------------------------------------
*/
* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
	background-repeat: no-repeat;
	vertical-align: baseline;
	font-weight: normal;
	outline: none;
	border: none;
 	-webkit-font-smoothing: antialiased;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
}


/*	-----------------------------------------
	Variables
	-----------------------------------------
*/

@postPreviewHeight: 300px;
@contentBorder: #d9d9d9;
@contentPadding: 15px;
@borderRadius: 4px;
@bgLight: #f3f3f3;
@bgDark: #ebebeb;
@brandColor: #84237a;
@headingLarge: 35px;
@headingMedium: 24px;
@headingSmall: 17px;
@dateColor: @brandColor;
@mainFontSize: 15px;
@bigFontSize: 17px;
@blackColorHover: #61A527;
@blackColor: #000;
@mainColor: #555;
@greyColorLight: #c7c7c7;
@marginReallyLarge: 120px;
@marginTopHeader: 70px;
@marginLarge: 40px;
@marginMedium: 30px;
@marginSmall: 20px;
@commentsOrange: #fec866;
@commentsTeal: #63e8d7;
@commentsPink: #ef7dbe;
@commentsRed: #fc8b61;
@commentsBlue: #62daeb;
@commentsFooterOrange: #f0bd60;
@commentsFooterTeal: #5edbcb;
@commentsFooterPink: #e374d2;
@commentsFooterRed: #f0835b;
@commentsFooterBlue: #5dcfde;
@commentsBorderOrange: #c99f51;
@commentsBorderTeal: #4eb5a7;
@commentsBorderPink: #bd60af;
@commentsBorderRed: #c96e4d;
@commentsBorderBlue: #4dabb8;



ul {
	margin: 0;
	padding: 0;
	list-style-position: inside;
}

a {
	color: @brandColor;
}

a.active:hover {
	text-decoration: none;
	cursor: default;
}


b {
	font-weight: bold;
}

b a {
	font-weight: bold;
}

iframe {
	max-width: 100%;
}

body {
	background: #f3f3f3;
	font-size: 13px;
	font-family: 'Open Sans';
	color: @mainColor;
	padding-top: 70px;
}

.hero-video {
	display: none;
	@media screen and (min-width: 801px) {
		display: inline-block;
		margin: auto; 
		width: 100vw; 
		height: 700px; 
		max-height: 70vh;
		object-fit: cover;	
	}
}

.hero-video-mobile {
	display: none; 
	@media screen and (max-width: 800px) {
		display: inline-block;
		width: 100%;
	}
}

.margin-top-page {
	margin-top: @marginTopHeader;
}

.margin-top-page-small {
	margin-top: @marginMedium;
}

.margin-bottom-medium {
	margin-bottom: @marginMedium;
}

.margin-bottom-large {
	margin-bottom: @marginLarge;
}

.wrapper-div {
	background: #fff;
	border-radius: @borderRadius;
	border: 1px solid @contentBorder;
}

.big-heading {
	font-size: @headingLarge;
	font-weight: normal;
	margin: 0;
	font-family: 'Merriweather Sans', sans-serif;
}

.medium-heading {
	font-size: @headingMedium;
	font-weight: normal;
	margin: 0;
	font-family: 'Merriweather Sans', sans-serif;
}

@media screen and (max-width: 768px) {
	.medium-heading {
		font-size: 20px
	}
}

.small-heading {
	font-size: @headingSmall;
	font-weight: normal;
	margin: 0;
	font-family: 'Merriweather Sans', sans-serif;
}

@media screen and (max-width: 768px) {
	.small-heading {
		font-size: 15px
	}
}

.large-paragraph {
	font-size: 18px;
}

.content-padding {
	padding: 15px;
}

.content-padding-large {
	padding: 0 100px;
}

.semi-bold {
	font-weight: 600;
}

html.progress,
html.progress * {
	cursor: progress!important;
}

h1.fourfour {
	line-height: 200px;
	font-weight: normal;
	font-size: 200px;
	font-family: 'Merriweather Sans', sans-serif;
}

img.grayscale {
    // filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+, Firefox on Android */
    // filter: gray; /* IE6-9 */
    // -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
}

@media screen and (max-width: 768px) {
	h1 {
		font-size: 20px
	}
	p {
		font-size: 14px;
	}
}


/*	-----------------------------------------
	Header
	-----------------------------------------
*/

header.main-header {
	background: #fff;
	height: 70px;
	// border-top: solid 5px #000;
	box-shadow: 0 0 7px rgba(0,0,0,0.4);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 10;
}

header.main-header .menu-huvudmeny-container {
	float: right;
}

header.main-header nav {
	margin-top: 3px;
}

header.main-header nav ul {
	list-style: none;
}


header.main-header nav ul li {
	float: left
}

header.main-header nav ul li a {
	height: 70px;
	padding: 0 20px;
	line-height: 70px;
	font-family: 'Merriweather Sans', sans-serif;
	font-size: 16px;
	color: @blackColor;
	display: inline-block;
}

header.main-header nav ul li.current-menu-item a,
header.main-header nav ul li.current-menu-parent a,
header.main-header nav ul li.current-vinnare-ancestor a,
header.main-header nav ul li.current-post-ancestor a {
	// background: @brandColor;
	color: @brandColor;
	text-decoration: underline;
}

.navbar .btn-navbar .icon-bar {
	background-color: #666;
	-moz-box-shadow: 0 1px 0 rgba(71, 71, 71, 0.35);
	-webkit-box-shadow: 0 1px 0 rgba(71, 71, 71, 0.35);
	box-shadow: 0 1px 0 rgba(71, 71, 71, 0.35);
}

.logo-img {
	max-height: 60px;
	margin-top: 5px;
}


/*	-----------------------------------------
	Footer
	-----------------------------------------
*/

footer.main-footer {
	background: #3e454d;
	min-height: 340px;
	color: #fff;
	margin-top: 100px;
	padding: @marginMedium 0;
}

footer.main-footer ul li {
	margin-top: 18px;
	list-style: none;
	
}

footer.main-footer ul li a {
	color: #fff;
}


footer.main-footer .footer-heading {
	font-size: 17px;
	font-weight: bold;
}


/*	-----------------------------------------
	Frontpage
	-----------------------------------------
*/

a.all-partners {
	font-size: 19px;
	width: 80%;
	text-align: center;
}

#banner {
	height: 380px;
	margin-bottom: 0;
	// background: #000;
}

#banner .carousel-inner,
#banner .carousel-inner .item,
#banner img {
	//width: 100%;
	//height: 100%;
	//   position: absolute;
    // top: 0;
    // bottom: 0;
    // right: 0;
    // left: 0;
    margin: auto;
    min-height: 50%;
    min-width: 50%;
	object-fit: cover;

	@media screen and (max-width: 768px) {
		min-height: 200px;
	}
}

#partners-slider {
	overflow: hidden;
	height: 75px;
}

#partners-slider .carousel-inner {
	width: 79%;
}

#partners-slider span.partners-start-caption {
	margin-right: 5%;
	float: left;
	font-size: 23px;
	color: #A6A6A6;
	font-family: Merriweather Sans, 'sans-serif';
	font-weight: 600;
}

#partners-slider .carousel-inner>.item>img, .carousel-inner>.item>a>img {
	display: inline-block;
	line-height: 75px;
	vertical-align: middle;
}

#partners-slider .carousel-inner .item a {
	display: inline-block;
	float: left;
	line-height: 75px;
	vertical-align: middle;
}

.social-feed {
	margin-top: 60px;
}

.social-feed .social-icon-wrapper {
	margin-bottom: 12px;
	height: 50px;
}

#banner .slider-overlay {
	// background: @dateColor;
	opacity: 0.20;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	min-height: 200px;
}

.carousel-caption {
	background: 0;
	bottom: 40px;
	text-align: center;
	font-family: 'Merriweather Sans', sans-serif;
	font-size: 23px;
	color: #fff;
}

h2.social-heading {
	font-size: @bigFontSize;
	font-weight: normal;
	line-height: 21px;
}

b.hashtag,
.social-time-since,
.social-feed a {
	color: #61a6cf;
}

.social-time-since {
	text-decoration: underline;
}


.carousel-caption p {
	text-shadow: 2px 0 4px rgba(0,0,0,.45);
	display: inline-block;
	background: rgb(27, 27, 27);
	background: rgba(27, 27, 27, 0.80);
	padding: 5px 10px;
	line-height: 26px;
}

.carousel-caption h1 {
	font-size: 37px;
	font-weight: normal;
	text-shadow: 2px 0 4px rgba(0,0,0,.45);
	display: inline-block;
	background: rgb(27, 27, 27);
	background: rgba(27, 27, 27, 0.80);
	padding: 8px 10px;
}

.carousel-caption-content {
	width: 85%;
	margin: 0 auto;
}

.brand-button {
	display: inline-block;
	background: #5cb85c;
	color: @bgLight;
	padding: 15px 40px;
	border-radius: 6px;
	font-size: 18px;
	font-family: Open Sans;
	font-weight: 600;
	transition: background linear 1.3s;

	&:hover {
		background: darken(#5cb85c, 10%);
		color: @bgLight;
		text-decoration: none;
		transition: background linear 0.3s;
	}
}

article.post-preview {
   height: @postPreviewHeight;
   margin-bottom: @marginMedium;
   height: 400px;
   box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.10);
   position: relative;

   .thumbnail-wrapper {
	 height: 250px;
	 overflow:hidden;
	 width: 100%;

	 img {
	   width: 100%;
	   height: auto;
	 }
   }
 }


article.post-preview footer a img {
	width: auto;
}

article.post-preview p {
	margin: auto 10px 10px;
	overflow: hidden;
}

article.post-preview.span3:nth-child(4n+5),
article.post-preview.span4:nth-child(3n+4),
article.partner-preview.span4:nth-child(3n+4),
.calendar-preview.span4:nth-child(3n+4),
article.span6.calendar-preview:nth-child(2n+3) {
	margin-left: 0;
}

article.post-preview a {
	color: inherit;
}

article.post-preview a:hover {
	text-decoration: normal;
}

article.post-preview h2 {
	font-weight: normal;
	// font-size: @headingSmall;
	font-size: @bigFontSize;
	margin-bottom: 0;
	line-height: 24px;
	padding: 0 5px;
}

article.post-preview time {
	font-size: 13px;
	font-weight: bold;
	color: @dateColor;
	margin-bottom: 5px;
	display: inline-block;
}

article.post-preview footer {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}

article.post-preview footer .popularity {
	height: 27px;
	line-height: 27px;
	padding-left: 15px;
}

.comment-icon {
	margin-left: 15px;
}

article.post-preview footer .popularity b {
	margin: 0 3px;
}

article.post-preview a.read-more {
	color: @blackColor;
	padding: 3px 12px;
	margin: 0 auto;
	display: inline-block;
	font-weight: bold;
}

article.post-preview footer a.read-more {
	color: @blackColor;
	padding: 3px 12px;
	margin: 0 auto;
	display: inline-block;
	font-weight: bold;
	margin-top: -40px;
	position: absolute;
	left: 50%;
	margin-left: -39px;
}

article.post-preview footer a.read-more:hover {
	background: @blackColorHover;
	color: #fff;
}

article.post-preview footer a:hover {
	text-decoration: none;
}

article.post-preview footer a img {
	height: auto;
}

.partners-start {
	background: #fff;
	margin-bottom: @marginLarge;
	border-bottom: 1px solid @contentBorder;
	height: 75px;
	line-height: 75px;	
}

.partners-start img {
	max-height: 50px;
	width: auto;
	margin: 0 30px;
	max-width: 120px;
}


#instagram-feed img.instagram-image {
	border-radius: @borderRadius;
	margin: 0 0 15px 15px;
	width: 70px;
	height: 70px;
}

.calendar {
	background: @bgDark;
	padding: @marginReallyLarge 0;
}

span.placeholder-date {
	display: inline-block;
	width: 100%;
	height: 130px;
	line-height: 130px;
	border-bottom: 1px solid #DDD;
}

.social-img-wrapper {
	width: 30px;
	display: inline-block;
}

span.placeholder-date time {
	font-size: 24px;
}

.padding-really-large-bottom {
	padding-bottom: @marginReallyLarge;
}

.padding-really-large-top {
	padding-top: @marginReallyLarge;
}


img.placeholder-image {
	border: 1px solid;
}

.calendar-preview {
	position: relative;
	height: 180px;
	border-radius: @borderRadius;
	margin-bottom: @marginSmall;
}

.calendar-caption {
	background: @blackColor;
	height: 44px;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	// font-size: @headingSmall;
	font-size: 17px;
	color: #fff;
	line-height: 44px;
	border-radius: @borderRadius;
	text-align: center;
}

.calendar-caption img {
	float: left;
	margin: -17px 0 0 20px; 	
}

aside.blog-list li.hasChildren > a {
	background: url('../img/sidebar-arrow.png') 94% center no-repeat;
} 

.calendar-preview img {
	border-radius: @borderRadius;
	max-height: 100%;
	width: 100%;
	min-height: 140px;
}

.calendar-preview time {
	position: absolute;
	left: -15px;
	top: -15px;
	background: url('../img/calendar-date.png') no-repeat;
	width: 40px;
	height: 40px;
	color: #fff;
	font-size: 13px;
	font-weight: bold;
	line-height: 40px;
	text-align: center;
}


/*	-----------------------------------------
	Blog
	-----------------------------------------
*/

.tag-cloud {
	list-style: none;
	margin-top: @contentPadding;
}

.post-preview-calendar-page footer {
	border-top: 1px solid @contentBorder;
}

.tag-cloud a {
	padding: 5px;
}

.sticky-menu {
	position: fixed;
	top: 70px;
	z-index: 9;
	width: 940px;
}

.blog-category-menu.sticky-menu {
	width: 700px;
}

.blog-placeholder {
	border-bottom: 1px solid @contentBorder;
}

span.date {
	color: @dateColor;
	font-weight: 600;
}

a.back-to {
	color: @dateColor;
	font-weight: 600;
	font-size: @bigFontSize;
	margin-bottom: 15px;
	display: inline-block;
}

@media screen and (max-width: 768px) {
	a.back-to {
		font-size: 14px;
	}
}

.sidebar-nav {
	min-height: 50px;
}

.sidebar-nav ul li {
	list-style: none;
}

.sidebar-nav ul li a {
	padding: 9px 15px;
	font-weight: bold;
	color: #777;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	display: inline-block;
	width: 100%;
}

.sidebar-nav ul li a.active,
.sidebar-nav ul li a:hover {
	background-color: #F2F2F2;
	color: #000;
}

.sidebar-nav ul li ul.children {
	padding-left: 20px;
}

.sidebar-nav h2 {
	display: inline-block;
	float: left;
}

.sub-nav {
}

h3#reply-title {
	display: none;
}

textarea#comment {
	float: left;
	width: 60%;
}

.comment-wrapper {
	display: inline-block;
	width: 100%;
	padding: 0 @contentPadding;
	margin-bottom: @contentPadding;
}

#commentform input[type="text"],
#commentform input[type="email"]{
	height: 30px;
	margin-right: 15px;
}

#commentform p {
	margin: -5px 0 10px;
}

#commentform label,
#commentform input[type="checkbox"] {
	float: left;
	margin-left: 10px;
	margin-top: 15px;
}

#commentform input[type="checkbox"] {
	margin-top: 19px;
}

#commentform p.form-submit {
	display: inline-block;
	float: left;
	margin-left: 20px;
}

p.form-submit #submit {
	background: @blackColor;
	border-radius: @borderRadius;
	padding: 20px 10px;
	color: #fff;
	font-weight: 600;
	font-family: 'Open Sans', sans-serif;
	font-size: 17px;
	float: left;
}

p.form-submit #submit:hover {
	background: @blackColorHover;
}


html body article.comments.span4 {
	padding: 16px;
	margin: 0;
	width: 33.3333%;
}

.comments p {
	line-height: 24px;
	margin: 10px 0 50px 0;
}

.comments-tape {
	position: absolute;
	top: -15px;
	left: 50%;
	margin-left: -42px;
}

.comments footer {
	position: absolute;
	bottom: 0;
	font-size: 13px;
	left: 0;
	width: 100%;
	padding-top: 10px;
}

.comments footer .like-bar {
	font-weight: 600;
	width: 100%;
	padding: 0px 15px;
}

.comment-meta {
	padding: 0 15px;
}

.comments-color {
	min-height: 120px;
	line-height: 22px;
	position: relative;
	padding: 15px;
}

#commentform p.comment-notes {
	margin-top: 10px;
}


.commentsBox (@background, @border){
	background: @background url('../img/comment-lines.png') repeat;
	border: 1px solid @border;
}

.comments-orange-iefix {
	.commentsBox(@commentsOrange, @commentsBorderOrange);
}
.comments:nth-child(5n+1) .comments-color {
	.commentsBox(@commentsOrange, @commentsBorderOrange);	
}
.comments-footer-orange-iefix {
	background: @commentsFooterOrange;
}
.comments:nth-child(5n+1) .comments-color .like-bar {
	background: @commentsFooterOrange;
}

.comments-teal-iefix {
	.commentsBox(@commentsTeal, @commentsBorderTeal);
}
.comments:nth-child(5n+2) .comments-color {
	.commentsBox(@commentsTeal, @commentsBorderTeal);
}
.comments-footer-teal-iefix {
	background: @commentsFooterTeal;
}
.comments:nth-child(5n+2) .comments-color .like-bar {
	background: @commentsFooterTeal;
}

.comments-pink-iefix {
	.commentsBox(@commentsPink, @commentsBorderPink);
}
.comments:nth-child(5n+3) .comments-color {
	.commentsBox(@commentsPink, @commentsBorderPink);
}
.comments-footer-pink-iefix {
	background: @commentsFooterPink;
}
.comments:nth-child(5n+3) .comments-color .like-bar {
	background: @commentsFooterPink;
}

.comments-red-iefix {
	.commentsBox(@commentsRed, @commentsBorderRed);
}
.comments:nth-child(5n+4) .comments-color {
	.commentsBox(@commentsRed, @commentsBorderRed);
}
.comments-footer-red-iefix {	
	background: @commentsFooterRed;
}
.comments:nth-child(5n+4) .comments-color .like-bar {
	background: @commentsFooterRed;
}

.comments-blue-iefix {
	.commentsBox(@commentsBlue, @commentsBorderBlue);
}
.comments:nth-child(5n+5) .comments-color {
	.commentsBox(@commentsBlue, @commentsBorderBlue);
}
.comments-footer-blue-iefix {
	background: @commentsFooterBlue;
}
.comments:nth-child(5n+5) .comments-color .like-bar {
	background: @commentsFooterBlue;
}

.sort-posts {
	height: 52px;
	font-size: @bigFontSize;
	padding: 8px 15px;
	margin-bottom: @marginMedium;
}

.sort-posts span.sort {
	font-weight: bold;
}

.sort-posts a {
	color: @mainColor;
	display: inline-block;
	padding: 7px 12px;
	margin-left: 10px;
}

.sort-posts a.active {
	color: #fff;
	background: @blackColor;
	border-radius: 30px;
}

form.search-tag {
	float: right;
}

form.search-tag input {
	border-radius: 20px;
	width: 165px;
	padding: 0 12px;
	height: 35px;
	background: right 10px ce url('../img/hashtag.png') #fff no-repeatnter;
}

aside.blog-list ul {
	list-style: none;
}

aside.blog-list ul.children li {
	padding-left: 35px;
}

aside.blog-list ul li {
	font-size: @bigFontSize;
}

aside.blog-list ul li a {
	padding: 15px 17px;
	display: inline-block;
	width: 100%;
	color: @mainColor;
}

html body aside.blog-list ul li a.active {
	background: @blackColor;
	color: #fff;
}

aside.blog-list ul li ul.children {
	height: 0;
	overflow: hidden;
}

.related-posts-header {
	padding: 24px 16px;
	margin: 0;
	line-height: 20px;
}

article.related-posts {	
	border-top: 1px solid @contentBorder;
	padding: 24px 16px;
}

article.related-posts a {
	color: @mainColor;
	font-weight: bold;
}

article.related-posts time {
	color: @dateColor;
	font-weight: bold;	
}

.loading-posts {
    -webkit-animation: rotation 0.7s infinite linear;
    animation: rotation 0.7s infinite linear;
}

.load-more-posts {
    height: 50px; position: fixed; top: 40%; display: none; width: 100%; text-align: center;
}

@-webkit-keyframes rotation {
    from {
    	-webkit-transform: rotate(360deg);
    }
    to {
    	-webkit-transform: rotate(0deg);
    }
}

@keyframes rotation {
    from {
    	transform: rotate(360deg);
    }
    to {
    	transform: rotate(0deg);
    }
}

.pagination {
	width: 100%;
	text-align: center;
	display: inline-block;
	height: 80px;
}

.pagination a {
	color: @greyColorLight;
	font-weight: 600;
	font-size: 15px;
}

.pagination hr {
	border-bottom: 0;
	border-top: 1px solid #e1e1e1;
	margin-top: -30px;
}

.loader-wrapper {
	width: 75px;
	margin: 0 auto;
	height: 50px;
	background: #f3f3f3;
	display: inline-block;
	cursor: pointer;
}

.like-button,
.share-button {
	border: 1px solid @contentBorder;
	border-radius: @borderRadius;
	color: #808080;
	width: 125px;
	font-size: @bigFontSize;
	font-weight: 600;
	height: 40px;
	line-height: 40px;
	text-align: center;
	cursor: pointer;
	float: left;
}

.like-button img,
.share-button img {
	margin: -3px 5px 0 0;
}

.like-button a {
	color: #808080;
}

.like-button .like-count {
	border-left: 1px solid @contentBorder;
	float: right;
	width: 35px;
	cursor: default;
}

.share-button {
	width: 90px;
	float: right;
	position: relative;
}

.share-dropdown {
	display: none;
	background: #fff;
	z-index: 9999;
	position: absolute;
	width: 215px;
	max-height: 170px;
	right: -40px;
	margin-top: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
	border-radius: @borderRadius;
	border: 1px solid @contentBorder;
}

.share-dropdown li {
	list-style: none;
	padding: 15px;
}

.share-dropdown li img {
	margin: 0;
	max-width: 20px;
	max-height: 20px;
}


.share-dropdown a {
	display: inline-block;
	width: 100%;
	text-align: left;
	color: @mainColor;
}

.social-bar {
	border-bottom: 1px solid @contentBorder;
	padding: @contentPadding;
}

.article-content {
	padding: @contentPadding;
}

/*	-----------------------------------------
	Partners
	-----------------------------------------
*/

.partner-preview {
	height: 170px;
	line-height: 170px;
	position: relative;
	padding: 0 10px;
	margin-bottom: 15px;
}

.partner-preview img {
	width: auto;
	max-width: 180px;
	height: auto;
	max-height: 80px;
}

@media screen and (max-width: 979px) {
	.partner-preview img {
		max-width: 150px;
		max-height: 65px;
	}
}

.partner-preview-homepage img {
	max-width: 130px;
}

@media screen and (max-width: 979px) {
	.partner-preview-homepage img {
		max-width: 150px;
		max-height: 65px;
	}
}

.partner-preview h2 {
	position: absolute;
	width: 93%;
	top: 41%;
	margin: 0;
	font-size: 19px;
	line-height: 25px;
}

.partner-heading:first-child {
	margin-top: 0;
}

.partner-heading {
	margin: 15px 0;
}

.partners-preview-hover span {
	font-weight: bold;
}


.partners-preview-hover {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: @brandColor;
	opacity: 0;
	color: #fff;
	-webkit-transition: opacity 0.23s ease-out;
}

.partner-preview:hover > a .partners-preview-hover {
	opacity: 0.95;
}

.partner-heading {
	height: 55px;
	margin: @marginMedium 0;
}

.partner-heading h1 {
	line-height: 20px;
}

.partners-content h1 {
	margin: @marginMedium 0;
	font-weight: bold;
}

.partners-single footer {
	border-top: 1px solid @contentBorder;
	line-height: 30px;
	display: inline-block;
}

.single-partners-social {
	display: inline-block;
	float: left;
	margin-right: 8px;
}


/*	-----------------------------------------
	Search
	-----------------------------------------
*/

form.search {
	position: relative;
}

.search-icon {
	background: #a1e069;
	border-radius: 20px;
	width: 40px;
	height: 40px;
	padding: 8px;
	text-align: center;
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
}

form.search input {
	height: 40px;
	width: 40px;
	border-radius: 40px;
	padding: 0;
	background: url('../img/search-icon-active.png') #FFF no-repeat right 10px center;
	float: right;
	padding: 0 28px 0 10px;
}

.search-results-header {
	font-family: 'Merriweather Sans', sans-serif;
	padding: 16px;
	font-size: @bigFontSize;
	margin-bottom: @marginMedium;
}

.search-results-header h1 {
	margin: 0;
	display: inline-block;
	line-height: 20px;
}

.search-result {
	border-bottom: 1px solid @contentBorder;
	padding: 16px;
}

.search-result h2 {
	font-weight: 600;
}

.search-result a {
	color: @mainColor;
}

.search-result:last-child {
	border-bottom: 0;
}

.search-highlight {
	color: @dateColor;
	font-weight: bold;
}

span.post-path {
	color: @blackColor;
}



/*	-----------------------------------------
	Contact
	-----------------------------------------
*/

.contact-page form {
	display: inline-block;
	width: 100%;
}

.contact-header img {
	max-height: 50px;
	width: auto;
}

.contact-page form input,
.contact-page form textarea {
	width: 100%;
	border: 1px solid @contentBorder;
	border-radius: @borderRadius;
	box-shadow: none;
	height: 45px;
	max-width: 100%;
	font-size: 15px;
	font-family: 'Open Sans', sans-serif;
}

.contact-page form textarea {
	height: 125px;
}

.contact-page form input.submit {
	width: 125px;
	float: right;
	background: #61a527;
	color: #fff;
	font-size: @headingSmall;
}

.contact-page form input.submit:hover {
	background: @blackColorHover;
}

.contact-page a {
	color: @blackColor;
}

.contact-header {
	border-bottom: 1px solid @contentBorder;
}

.contact-info {
	line-height: 26px;
	font-size: @bigFontSize;
	margin-bottom: @marginLarge;
	ul{
		margin-top: 20px;
		margin-bottom: 20px;
	}
	li{
		line-height: 26px;
	    margin-top: 20px;
	    
	    list-style-position: outside;
	}
}
.bold{
	font-weight: 700;
}

@media screen and (max-width: 768px) {
	.contact-info {
		font-size: 17px;
		li{
		    list-style-position: inside;
		}
	}
}

.contact-info span {
	font-size: @bigFontSize;
}

.contact-info img {
	margin-right: 19px;
	margin-top: -2px;
}

.contact-answer{
	display: none;
	&#contact-error{
		color: red;
	}
}

.contact-page form input[type="file"]{
	    border: none;
    display: flex;
        margin-top: 20px;
}

/*	-----------------------------------------
	Application
	-----------------------------------------
*/
@media screen and (min-width: 768px) {
	.application-span.span5{
		width: 46%;

	}
	.application-span.offset2{
		margin-left: 8%;
	}
	
}

#form{
	label{
		cursor: auto;
	}
	input:not([type="submit"]){
		width: 100%;
	}
}

.requiredField.inputError{
	border-color: red;
}
/*	-----------------------------------------
	Text pages
	-----------------------------------------
*/

.text-pages h1 {
	font-weight: 600;
	margin: @marginSmall 0;
}

.text-pages p,
.partners-content p {
	margin-bottom: @marginSmall;
}

.text-pages img.featured {
	border: 2px solid @blackColor;
	width: 100%;
	max-height: 436px;
}

@media (min-width: 1601px){
	#banner {
		width: 1600px;
		width: 100%;
		// border: 5px solid #FFF;
		margin: 25px auto 0;
		height: 450px;
		box-shadow: 0 -3px 1px -3px #D9D9D9, 3px 0px 1px -3px #D9D9D9, -3px 0px 1px -3px #D9D9D9;
	}
	.partners-start {
		max-width: 1600px;
		margin: 0 auto;
		margin-bottom: 30px;
		box-shadow: 0 -3px 1px -3px #D9D9D9, 3px 0px 1px -3px #D9D9D9, -3px 0px 1px -3px #D9D9D9;
	}
}

@media (min-width: 1200px){
	
	.sticky-menu {
		width: 1200px;
	}

	.blog-category-menu.sticky-menu {
		width: 892px;
	}

}

@media (max-width: 1200px){

	header.main-header nav ul li a {
		padding: 10px 18px;
		font-size: 15px;
	}
	
	#partners-slider .carousel-inner {
		width: 75%;
	}

	.partners-start img {
		max-width: 75px;
	}

}

@media (max-width: 979px) and (min-width: 768px){

	.calendar-preview {
		height: 145px;
	}

	article.post-preview {
		height: 440px;
	}

	.partners-start img {
		margin: 0 15px;
	}
	
	#partners-slider span.partners-start-caption {
		margin-right: 3%;
		font-size: 19px;
	}

}

@media (max-width: 979px){
	
	span.sort {
		display: none;
	}
	
	form.search-tag input {
		width: 150px;
	}

	.row-fluid [class*="span"].nav-collapse.navbar-responsive-collapse.collapse {
		min-height: 0;
		height: 0;
		padding: 0;
	}

	html body #blog-container {
		width: 100%;
		margin-left: 0;
	}
	
	html body .responsive-padding {
		padding: 0 15px;
	}

	header.main-header {
		padding-left: 15px;
		padding-right: 15px;
	}
	
	header.main-header .menu-huvudmeny-container {
		float: left;
		width: 100%;
	}

	header.main-header nav ul li {
		float: none;
	}

	header.main-header nav ul li a {
		width: 100%;
		color: #fff;
	}

	header.main-header nav.nav {
		background: @blackColor;
		padding: 10px 0;
		float: right;
		width: 100%;
		margin: 8px 0 0 0;
	}

	form.search input {
		max-width: 220px;
		width: 100%;
		float: left;
		margin: 10px 0 0 20px;
	}
	
	.sticky-menu {
		width: 100%;
		left: 0;
	}

	.blog-category-menu.sticky-menu {
		width: 694px;
		left: auto;
	}

	.single-blog-page .row-fluid [class*="span"] {
		width: 100%;
	}
	
	#banner {
		height: 280px;
	}


  article.post-preview {

	.thumbnail-wrapper {
	  height: 200px;
	  overflow:hidden;
	  width: 100%;

	  article.post-preview a img {
		width: 100%;
		height: auto;
	  }
	}
  }



}

@media (max-width: 768px){
	
	footer.main-footer {
		height: 70px;
		min-height: 70px;
		padding: 0;
		line-height: 70px;
		overflow: hidden;
	}
	
	#partners-slider {
		padding: 0 15px;
	}

	span.partners-start-caption {
		font-size: @bigFontSize;
	}
	
	.partners-start img {
		max-width: 80px;
	}
	
	#partners-slider .carousel-inner {
		width: 60%;
	}

	#commentform p.form-submit {
		margin-left: 0;
	}

	.mobile-footer {
		width: 50%;
		float: left;
	}

	.mobile-footer input {
		height: 40px;
		margin-top: 7px;
		max-width: 100%;
	}
	
	.partners-start a {
		margin-left: 0;
		font-size: 15px;
	}

	.partners-start img {
		margin-right: 20px;
		max-height: 43px;
		width: auto;
	}

	.share-dropdown {
		width: 175px;
		right: -25px;
		font-size: 15px;
	}

	p.form-submit {
		margin: 5px 0;
		width: 100%;
	}


	textarea#comment {
		width: 100%;
	}

	html body article.span4.comments {
		width: 100%;
		padding: 15px 0;
	}

	.single-partners-social {
		float: none;
		display: block;
	}

	html body .sticky-menu, .blog-category-menu.sticky-menu {
		width: 100%;
		left: 0;	
		top: 50px;
	}

	body {
		padding-left: 0;
		padding-right: 0;
		padding-top: 50px;
	}

	header.main-header {
		height: 50px;
		padding-top: 3px;
		padding-bottom: 3px;
	}

	header.main-header nav.nav {
		background: @blackColor;
		padding: 10px 0;
		float: left;
		width: 100%;
		margin: 8px 0 0;
	}
	
	header.main-header .logo {
		width: auto;
		float: left;
	}

	.margin-top-page {
		margin-top: 25px;
	}

	.margin-top-page-small {
		margin-top: 20px;
	}



  // ARTICLE
  article.post-preview {

	text-align:left;
	height:auto;
	padding-bottom:23px;

	h2, p, time {
		width:100%;
	}

	p {
	  margin: 10px 0;
	  padding-left: 5px;

	  //width:auto;
	}

	.thumbnail-wrapper {
	  display:inline-block;
	  height:auto;
	  max-width: 220px;
	  width:43%;

	  article.post-preview a img {
		width: 100%;
		height: auto;
	  }
	}

	oca {
	  display:inline-block;
	  vertical-align: top;
	  width:50%;
	  margin: 0 2%;

	  time {
		padding-left: 5px;
	  }
	}

  }


	.sort-posts {
		height: 43px;
		padding: 5px 0;
		text-align: center;
		float: left;
	}
	
	.sort-posts a {
		margin-left: 0px;
		font-size: 11px;
		padding: 5px;
	}

	form.search-tag.visible-phone {
		float: left;
		margin-bottom: 0;
	}
	form.search-tag input.search-input {
		width: 100%;
	}

	form.search-tag input {
		border-radius: 20px;
		height: 35px;
	}

	#banner {
		height: 210px;
	}

	.calendar-preview time {
		top: -10px;
		left: -10px;
	}
	
	article.post-preview time {
		margin-bottom: 6px;	
	}	
	
	article.post-preview p {
		font-size: 14px;
	}
	
	.logo-img {
		max-height: 35px;
	}

	#partners-slider {
		height: 50px;
		line-height: 50px;
	}
}

.box-module-container {
	height: 150px;
	margin-bottom: 20px;
	overflow: hidden;
	text-align: center;
	background: white;
	box-shadow: 0 0 2px #000;
}

.box-module-table {
	display: table;
	width: 100%;
	height: 100%;
}

.box-module-table-cell {
	display: table-cell;
	vertical-align: middle;
	width: 100%;
	height: 100%;
}

.box-module-container:nth-child(4n+5) {
	margin-left: 0;
}

.right-sticky-container {
	position: fixed;
	right: 0;
	top: 30%;
	z-index: 999;
	.twitter,
	.facebook,
	.instagram,
	.linkedin {
		text-align: right;
	}
	.twitter,
	.facebook,
	.instagram,
	.linkedin
	.nominate {
		margin-bottom: 5px;
	}
}

footer .brand-button {
	padding: 20px 15px;
	line-height: 0;
}

blockquote {
	border: none;
	padding: 0 30px;
}


.opening-quote,
.closing-quote {
	position:relative;
	&:before {
		position: absolute;
		font-size: 60px;
		color: #ccc;
	}
}

.opening-quote:before {
	top: 0;
	left: -30px;
	content: '\201C';
}

.closing-quote:before {
	top: 10px;
	right: -30px;
	content: '\201D';
}

::selection {
	background: @brandColor; /* Safari */
	color: white;
}
::-moz-selection {
	background: @brandColor; /* Firefox */
	color: white;
}


@media screen and(max-width:340px) {

  article.post-preview {
	height: @postPreviewHeight !important;
	margin-bottom: @marginMedium !important;
	height: auto !important;
	box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.10) !important;
	position: relative !important;

	.thumbnail-wrapper {
	  height: 250px !important;
	  overflow:hidden !important;
	  width: 100% !important;
	  max-width:100%;

	  img {
		width: 100% !important;
		height: auto !important;
	  }
	}

	oca {
	  width:auto !important;
	}
  }

}